<template>
  <div class="bg-white p-6 sm:p-8  m-4 sm:m-0 rounded-lg modal-md pb-6">
    <div class="text-2xl my-8 mt-2 font-semibold font-merri text-center">
      How it works...
    </div>
    <div class="flex flex-row items-center">
      <div class="p-0 py-2 mr-3 bg-white">
        <font-awesome-icon
          :icon="['fal', 'layer-plus']"
          class="text-gray-400 text-2xl "
        />
      </div>

      <div class="font-semibold flex-grow ">Choose your plan.</div>
    </div>
    <div class=" border-l-4 border-dotted ml-2 pl-6 pb-4">
      <p>
        You choose the wet / dry food you would want to receive every month.
      </p>
    </div>
    <div class="flex flex-row items-center">
      <div class="p-0 py-2 mr-3 bg-white">
        <font-awesome-icon
          :icon="['fal', 'calendar-week']"
          class="text-gray-400 text-2xl "
        />
      </div>

      <div class="font-semibold flex-grow ">Trial the food</div>
    </div>
    <div class=" border-l-4 border-dotted ml-2 pl-6 pb-4">
      <p>
        Receive a taster box with a selection of our food and flavours to try
        over 2 weeks.
      </p>
      <div class="flex flex-row items-center mt-2 mb-4">
        <div
          class="w-6 h-6 py-3 flex items-center justify-center  bg-white flex-none rounded-full mr-3"
        >
          <font-awesome-icon :icon="['far', 'check']" class="text-teal-400 " />
        </div>
        <div class="flex-grow">Easily adjust or cancel at anytime!</div>
      </div>
    </div>

    <div class="flex flex-row items-center">
      <div class="p-0 py-0 -ml-2 mr-2 bg-white text-4xl">
        😻
      </div>
      <div class="font-semibold flex-grow">After 2 weeks</div>
    </div>
    <div class=" border-l-4 border-gray-200  ml-2 pl-6 pb-4 mb-12">
      Receive your 1st month of healthy food!
      <div class="text-gray-600 text-sm">(then every 28 days thereafter)</div>
    </div>

    <form @submit.prevent="closeModal" novalidate="true">
      <loading-button
        :is-loading="false"
        :is-enabled="true"
        :is-full="true"
        class="mt-4"
        label="Ok, got it thanks!"
      />
    </form>
  </div>
</template>

<script>
import LoadingButton from '@/components/LoadingButton';

export default {
  components: {
    LoadingButton
  },
  methods: {
    closeModal: function() {
      // this.$ma.trackEvent({
      //   category: "Click",
      //   action: "Closed trial guide"
      // });
      localStorage.setItem('show_tg', false);
      this.$parent.$emit('make-close');
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-md {
  max-width: 420px;
  width: auto;
}
</style>
