<template>
  <div
    class="throbber flex h-full items-center justify-center"
    :class="[hasSize, hasColor]"
  >
    <div class="ball"></div>
    <div class="ball"></div>
    <div class="ball"></div>
  </div>
</template>

<script>
export default {
  props: ["size", "color"],
  computed: {
    hasSize: function () {
      return "size-" + this.size;
    },
    hasColor: function () {
      return "color-" + this.color;
    },
  },
};
</script>

<style lang="scss" scoped>
.throbber .ball {
  width: 8px;
  height: 8px;
  display: inline-block;
  margin-right: 4px;
  background-color: white;
  border-radius: 8px;
  animation: action 0.7s infinite;
}

.throbber .ball:last-child {
  margin-right: 0px;
}

.throbber .ball:nth-child(1) {
  animation-delay: 0s;
}
.throbber .ball:nth-child(2) {
  animation-delay: 0.1s;
}
.throbber .ball:nth-child(3) {
  animation-delay: 0.2s;
}

.throbber.size-lg .ball {
  width: 12px;
  height: 12px;
  margin-right: 6px;
}

.throbber.color-dark .ball {
  background-color: white;
}

.throbber.color-light .ball {
  background-color: #cbd5e1;
}

@keyframes action {
  0% {
    transform: translateY(4px);
    opacity: 1;
  }
  50% {
    transform: translateY(-4px);
    opacity: 0.6;
  }
  100% {
    transform: translateY(4px);
    opacity: 1;
  }
}
</style>
