<template>
  <div class="relative block">
    <transition
      mode="in-out"
      enter-active-class="animated "
      leave-active-class="animated "
    >
      <throbber
        v-if="isLoading"
        class="z-10"
        :color="isCamo ? 'light' : 'dark'"
      />
    </transition>
    <button
      type="submit"
      :disabled="!isEnabled || isLoading"
      ref="submitBtn"
      class="btn"
      :class="{
        'btn-disabled pointer-events-none': !isEnabled || isLoading,
        'pl-16': isLoading,
        'w-full text-center': isFull,
        'btn-secondary': secondary,
        'btn-caution': caution,
        'btn-camo !p-0': isCamo
      }"
    >
      <div
        class="default-animation flex items-center text-center"
        :class="{
          'opacity-0': isLoading,
          'justify-center': isFull,
          'block w-full': isCamo
        }"
      >
        <slot></slot>
        <div class="w-full" v-if="label">
          {{ label }}
        </div>
      </div>
    </button>
  </div>
</template>

<script>
import Throbber from '@/components/Throbber';

export default {
  components: {
    Throbber
  },
  props: {
    isLoading: Boolean,
    isEnabled: Boolean,
    label: String,
    secondary: Boolean,
    caution: Boolean,
    isFull: Boolean,
    isCamo: Boolean
  },
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
.throbber {
  position: absolute;
  left: 0;
  right: 0;
  /* Add any other CSS properties here */
}

.btn-disabled {
  opacity: 0.5;
  cursor: default;
}
</style>
