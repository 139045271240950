var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative block"},[_c('transition',{attrs:{"mode":"in-out","enter-active-class":"animated ","leave-active-class":"animated "}},[(_vm.isLoading)?_c('throbber',{staticClass:"z-10",attrs:{"color":_vm.isCamo ? 'light' : 'dark'}}):_vm._e()],1),_c('button',{ref:"submitBtn",staticClass:"btn",class:{
      'btn-disabled pointer-events-none': !_vm.isEnabled || _vm.isLoading,
      'pl-16': _vm.isLoading,
      'w-full text-center': _vm.isFull,
      'btn-secondary': _vm.secondary,
      'btn-caution': _vm.caution,
      'btn-camo !p-0': _vm.isCamo
    },attrs:{"type":"submit","disabled":!_vm.isEnabled || _vm.isLoading}},[_c('div',{staticClass:"default-animation flex items-center text-center",class:{
        'opacity-0': _vm.isLoading,
        'justify-center': _vm.isFull,
        'block w-full': _vm.isCamo
      }},[_vm._t("default"),(_vm.label)?_c('div',{staticClass:"w-full"},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e()],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }